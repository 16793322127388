<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title mb-0">
            <h6>News</h6>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  
}
</script>